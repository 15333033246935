var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.roles'))+" ")]},proxy:true},(_vm.hasAccess('roles', 'create'))?{key:"button",fn:function(){return [_c('b-button',{staticClass:"ml-auto",attrs:{"to":{
                    name: 'Roles Create'
                },"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card"},[_c('b-table',{attrs:{"fields":_vm.tableHeader,"items":_vm.roles,"responsive":true,"striped":"","borderless":"","outlined":""},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
                var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(actions)",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_c('table-action-btns',{attrs:{"item":item,"index":index,"delete-handler":_vm.deleteRole,"delete-access":{ section: 'roles', permission: 'delete' },"update-access":{ section: 'roles', permission: 'update' }},on:{"openModal":_vm.navigateToSingle}})]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }