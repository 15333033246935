<script>
import {
    BBadge,
    BButton,
    BCardText,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BRow,
    BSpinner,
    BTable
} from 'bootstrap-vue';
import api from '@/services/api';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import vSelect from 'vue-select';
import { sortItems } from '@/util/helper';
import TableActionBtns from '@/components/TableActionBtns';
import PageTitle from '@/components/PageTitle';

export default {
    name: 'User',
    components: {
        PageTitle,
        TableActionBtns,
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BSpinner,
        BCardText,
        vSelect
    },
    mixins: [togglePasswordVisibility],
    data: () => ({
        roles: [],
        newRole: {
            title: '',
            email: '',
            role_id: null,
            password: ''
        }
    }),
    computed: {
        tableHeader() {
            return [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        }
    },
    methods: {
        async getRoles() {
            const { data } = await api.roles.get();
            this.roles = sortItems(data, 'name');
        },

        async deleteRole(id, index) {
            await api.roles.delete(id);
            this.roles.splice(index, 1);
        },

        navigateToSingle(item) {
            this.$router.push({
                name: 'Roles Single',
                params: {
                    id: item.id
                }
            });
        }
    },
    mounted() {
        this.getRoles();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.roles') }}
            </template>
            <template #button v-if="hasAccess('roles', 'create')">
                <b-button
                    :to="{
                        name: 'Roles Create'
                    }"
                    variant="success"
                    class="ml-auto"
                >
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <b-row>
            <!--  TABLE  -->
            <b-col cols="12">
                <div class="card">
                    <b-table
                        :fields="tableHeader"
                        :items="roles"
                        :responsive="true"
                        striped
                        borderless
                        outlined
                    >
                        <template #cell(index)="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template #cell(actions)="{ item, index }">
                            <table-action-btns
                                :item="item"
                                :index="index"
                                :delete-handler="deleteRole"
                                :delete-access="{ section: 'roles', permission: 'delete' }"
                                :update-access="{ section: 'roles', permission: 'update' }"
                                @openModal="navigateToSingle"
                            />
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
